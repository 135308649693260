/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, Grid, Heading, Text, Flex, Box, Button } from 'theme-ui'
import { useMediaQuery } from 'react-responsive'
import { responsive } from '../../utils/helpers'
import { onClickGtag } from '../../utils/gtag'
import { Stack } from 'raam'
import PlayerCard from './playerCard'
import PlayerGoal from './playerGoal'
import { Wrap } from 'raam'
import Logo from './logo'
import { Link } from 'gatsby'
import {
  getScore,
  getTeam,
  formatHighlights,
  getDate,
  getTime,
  getVenue,
  getResult,
  getLeague,
} from '../../api_aws/opta'
import useStyle from './hooks/useStyle'

const Versus = props => {
  const style = useStyle(props.color, props.variant, props.highlights)
  const isMobile = useMediaQuery({ query: '(max-width: 649px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 650px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 780px)' })
  const [homeTeam, setHomeTeam] = useState(props.home)
  const [awayTeam, setAwayTeam] = useState(props.away)
  const [league, setLeague] = useState(props.league ? props.league : {})
  const [scores, setScores] = useState(props.scores)
  const [date, setDate] = useState()
  const [time, setTime] = useState()
  const [venue, setVenue] = useState()
  const [result, setResult] = useState(props.resultData)
  const [highlights, setHighlights] = useState(props.highlightData)

  useEffect(() => {
    ;(async () => {
      if (!props.fixtureData) return
      // Get home info
      const home = getTeam(props.fixtureData, 'home')
      if (home) setHomeTeam(home)
      // Get away info
      const away = getTeam(props.fixtureData, 'away')
      if (away) setAwayTeam(away)
      // Highlights
      if (home && away) {
        setHighlights([
          formatHighlights(home.highlights),
          formatHighlights(away.highlights),
        ])
      }
      // Get league
      const league = await getLeague(props.fixtureData)
      setLeague(league)
      // Get scores, date etc
      setScores(getScore(props.fixtureData))
      setDate(getDate(props.fixtureData))
      setTime(getTime(props.fixtureData, props.ignoreLocalTime))
      setVenue(getVenue(props.fixtureData))
      setResult(getResult(props.fixtureData))
    })()
  }, [
    props.fixtureData,
    setHomeTeam,
    setAwayTeam,
    setLeague,
    setScores,
    setDate,
    setTime,
    setVenue,
    setResult,
    setHighlights,
  ])

  const Scores = () => {
    if (!scores) return null

    return (
      <React.Fragment>
        <Flex>{scores.home}</Flex>
        <Box ml={1} mr={1}>
          -
        </Box>
        <Flex>{scores.away}</Flex>
      </React.Fragment>
    )
  }

  const PlayerCards = ({ cards }) => {
    if (!cards) return null
    const yellowCards = cards.reduce((total, x) => {
      if (x === 'yellow') total++
      return total
    }, 0)
    const redCards = cards.reduce((total, x) => {
      if (x === 'red') total++
      return total
    }, 0)
    if (yellowCards >= 2 && !redCards) {
      cards.push('red')
    }
    return (
      <Box sx={style.cards}>
        {cards.map((x, i) => {
          return (
            <PlayerCard
              key={i}
              variant={x}
              sx={{
                position: 'relative',
                top: `-${i * 3}px`,
                left: `-${i * 3}px`,
              }}
            />
          )
        })}
      </Box>
    )
  }

  const PlayerBalls = ({ goals }) => {
    if (!goals || isMobile) return null
    return (
      <Box sx={style.cards}>
        {[goals].map((_, i) => {
          return (
            <PlayerGoal
              key={i}
              sx={{
                position: 'relative',
                top: `-${i * 3}px`,
                left: `-${i * 3}px`,
              }}
            />
          )
        })}
      </Box>
    )
  }

  const Highlight = x => {
    if (isMobile && !x.goals) return null
    return (
      <Text
        sx={{
          ...style.highlight,
          textAlign:
            x.team === 0
              ? responsive('center', 'right')
              : responsive('center', 'left'),
        }}
      >
        {x.content}
        <PlayerCards cards={x.cards} />
        <PlayerBalls goals={x.goals} />
      </Text>
    )
  }

  const Highlights = ({ team }) => {
    if (!props.highlights) return null

    if (!highlights || !highlights[team]) return null

    return (
      <Stack gap={1} sx={style.highlightWrapper}>
        {highlights[team].map((x, i) => {
          return <Highlight {...x} team={team} key={i} />
        })}
      </Stack>
    )
  }

  const Buttons = () => {
    if (!props.tickets && !props.travel && !props.live && !props.buttons)
      return null

    if (props.buttons) {
      return <Wrap sx={style.buttons}>{props.buttons}</Wrap>
    }

    return (
      <Wrap sx={style.buttons}>
        {props.tickets && (
          <Button
            variant="primary"
            as={Link}
            to="/tickets/match"
            onClick={() => onClickGtag('click', 'Match Centre', 'Tickets')}
          >
            Tickets
          </Button>
        )}
        {props.travel && (
          <Button variant="secondary" as={Link} to="/tickets/travel">
            Travel
          </Button>
        )}
        {props.live && (
          <Button
            variant="tertiary"
            as={Link}
            to="/match-centre/fgr-live"
            onClick={() => onClickGtag('click', 'Match Centre', 'FGR Live')}
          >
            FGR Live
          </Button>
        )}
      </Wrap>
    )
  }

  const League = () => {
    if (props.variant === 'tiny') return null
    if (!league || !league.logo) return null

    return <img sx={style.competition} src={league.logo} alt={league.name} />
  }

  const imageSpaceDesktop = props.variant === 'large' ? '120px' : '105px'
  const homeName = homeTeam
    ? homeTeam.shortName
      ? homeTeam.shortName
      : homeTeam.name
    : ''
  const awayName = awayTeam
    ? awayTeam.shortName
      ? awayTeam.shortName
      : awayTeam.name
    : ''

  return (
    <Box sx={{ width: '100%' }} {...props} result={null}>
      {props.title && (
        <Heading variant="heading" sx={style.heading}>
          {props.title}
        </Heading>
      )}
      {props.titlestrong && (
        <Heading variant="heading" sx={style.headingStrong}>
          {props.titlestrong}
        </Heading>
      )}
      <Box sx={style.outer}>
        <League />
        <Grid
          gap={2}
          columns={
            props.variant === 'small' ||
            props.variant === 'tiny' ||
            props.variant === 'stacked'
              ? responsive('33% 1fr 33%', '1fr 45px 1fr')
              : responsive('33% 1fr 33%', '1fr 85px 1fr')
          }
          sx={style.container}
        >
          <Flex sx={style.teamOne}>
            <Box sx={{ flexShrink: 0, ml: responsive(0, '25px') }}>
              {homeTeam?.crest && <img src={homeTeam.crest} alt={homeName} />}
              {!homeTeam?.crest && <Logo name={homeName} sx={style.image} />}
            </Box>

            {isDesktop && (
              <Box sx={style.teamDetails}>
                <Text sx={style.titleOne}>{homeName}</Text>
              </Box>
            )}
          </Flex>
          <Flex sx={style.vsContainer}>
            <Text sx={style.vsText}>
              {props.variant === 'tiny' ? '' : scores ? <Scores /> : 'VS'}
            </Text>
          </Flex>
          <Flex sx={style.teamTwo}>
            <Box sx={{ flexShrink: 0, mr: responsive(0, '25px') }}>
              {awayTeam?.crest && <img src={awayTeam.crest} alt={awayName} />}
              {!awayTeam?.crest && <Logo name={awayName} sx={style.image} />}
            </Box>
            {isDesktop && (
              <Box sx={style.teamDetails}>
                <Text sx={style.titleTwo}>{awayName}</Text>
              </Box>
            )}
          </Flex>
        </Grid>
        {(props.highlights || (props.variant !== 'tiny' && isTablet)) && (
          <Grid
            gap={2}
            columns={
              props.variant === 'small' ||
              props.variant === 'tiny' ||
              props.variant === 'stacked'
                ? 1
                : responsive('33% 1fr 33%', `1fr 335px 1fr`, 6)
            }
          >
            <Flex sx={style.metaOne}>
              <Highlights team={0} />
            </Flex>
            {props.variant !== 'tiny' && isTablet && (
              <Text variant="small" sx={style.smallText}>
                {date}
                <br />
                {venue}
                <br />
                {time ? `${time} Kick off` : ''}
              </Text>
            )}
            {props.variant === 'tiny' || (isMobile && <Box></Box>)}

            <Flex sx={style.metaThree}>
              <Highlights team={1} />
            </Flex>
          </Grid>
        )}
      </Box>
      {props.variant !== 'tiny' && isMobile && (
        <Text variant="small" sx={style.smallText}>
          {date}
          <br />
          {venue}
          <br />
          {time ? `${time} Kick off` : ''}
        </Text>
      )}
      {result && props.result && (
        <Text
          variant="small"
          sx={{
            ...style.smallText,
            fontWeight: 700,
            textTransform: 'capitalize',
          }}
          mt={7}
        >
          {result}
        </Text>
      )}
      <Buttons />
    </Box>
  )
}

export default Versus
export { Logo }

/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

const PlayerCard = (props) => {

  const styleSX = {
    display: 'inline-block',
    width: '6px',
    height: '10px',
    borderRadius: '1px',
    backgroundColor: props.variant
  }

  return (
    <Box sx={styleSX} {...props} />
  )
}

export default PlayerCard
import { useThemeUI, get } from 'theme-ui';
import { responsive } from '../../../utils/helpers';

const useStyle = (color, variant, hasHighlights) => {

  const { theme } = useThemeUI()

  const team =  {
    alignItems: 'center',
    flexDirection: 
      variant === 'stacked' 
        ? 'column' 
        : responsive('column', 'row'),
  };

  const image = {
    width: 
      variant === 'large' 
        ? responsive('85px', '100px') 
        : variant === 'tiny' 
          ? '60px' 
          : '85px',
    height: 
      variant === 'large' 
        ? responsive('85px', '100px') 
        : variant === 'tiny' 
          ? '60px' 
          : '85px',
    marginBottom: 
      variant === 'stacked' 
        ? 3 
        : responsive(3, 0)
  };

  const title = {
    lineHeight: 'heading',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    fontSize: 
      variant === 'large' 
        ? 9 
        : 6,
    color: 
      color 
        ? color 
        : variant === 'large' 
          ? 'primary' 
          : 'white',
    wordBreak: 'break-word',
  };

  return {
    heading: {
      textAlign: 'center', 
      color: 
        color 
          ? color 
          : variant === 'large' 
            ? 'white' 
            : 'black',
    },
    headingStrong: {
      textAlign: 'center', 
      color: 
        color 
          ? color 
          : variant === 'large' 
            ? 'primary' 
            : 'black',
      fontSize: responsive(11, '100px')
    },
    outer: {
      paddingTop: 
        variant === 'tiny' 
          ? '0px' 
          : variant === 'stacked' 
            ? `${get(theme, 'space.4')}px` 
            : responsive(`${get(theme, 'space.4')}px`, `35px`),
    },
    container: {
      maxWidth: 
        hasHighlights 
          ? 'none'
          : responsive('300px', 'none', 5),
      margin: 
        hasHighlights 
          ? '0'
          : '0 auto',
      position: 'relative',
      padding: 
        variant === 'tiny' 
          ? '0px' 
          : variant === 'stacked' 
            ? `0px 0px ${get(theme, 'space.4')}px` 
            : responsive(`0px 0px ${get(theme, 'space.4')}px`, `0px 0px 20px`),
    },
    image: image,
    team: team,
    teamDetails: {
      position: 'relative'
    },
    teamOne: {
      ...team,
      flexDirection: 
        variant === 'stacked' 
          ? 'column' 
          : responsive('column', 'row-reverse'), 

      'img': {
        ...image,
      }
    },
    teamTwo: {
      ...team,

      'img': {
        ...image,
      }
    },
    title: title,
    titleOne: {
      ...title,
      textAlign: 
        variant === 'stacked' 
          ? 'center' 
          : responsive('center', 'right')
    },
    titleTwo: {
      ...title,
      textAlign: 
        variant === 'stacked' 
        ? 'center' 
        : responsive('center', 'left')
    },
    vsContainer: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
    },
    vsText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: 'heading',
      textTransform: 'uppercase',
      fontFamily: 'heading',
      fontSize: 
        variant === 'large' 
          ? responsive(8, 10) 
          : 6,
      color: 
        color 
          ? color 
          : variant === 'large' 
            ? 'white' 
            : 'black' 
    },
    competition: {
      width: '50px',
      height: 'auto',
      margin: '0px auto',
      display: 'block',
      // position: 'absolute',
      // top: '0px',
      // left: '50%',
      // transform: 'translateX(-50%)',
      color: 
        color ? 
          color 
          : 'white'
    },
    smallText: {
      textAlign: 'center',
      fontFamily: 'body',
      fontWeight: 400,
      color: 
        color 
          ? color 
          : 'white'
    },
    metaOne: {
      justifyContent: responsive('center', 'flex-end', 6)
    },
    metaThree: {
      justifyContent: responsive('center', 'flex-start', 6)
    },
    highlightWrapper: {
      margin: 0
    },
    highlight: {
      fontSize: 1,
      lineHeight: 'body',
      fontFamily: 'body',
      textAlign: 'right',
      fontWeight: 400,
      color: 
        color 
          ? color 
          : 'white'
    },
    cards: {
      display: 'inline-block',
      marginLeft: '10px'
    },
    buttons: {
      justifyContent: 'center',
      marginTop: 2
    }
  }
}

export default useStyle
/** @jsx jsx */
import { jsx, Flex, Text } from 'theme-ui';

const Logo = (props) => {

  const containerSX = {
    width: props.variant === 'small' ? '32px' : '85px',
    height: props.variant === 'small' ? '32px' : '85px',
    background: 'grey',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%'
  }

  const textSX = {
    fontFamily: 'heading',
    fontSize: props.variant === 'small' ? '14px' : '36px',
    color: 'black',
    textTransform: 'uppercase'
  }

  const Initials = () => {
    if (!props.name)
      return null

    return props.name.split(" ").map((n)=>n[0]).join(" ");
  }

  return (
    <Flex sx={containerSX} className={props.className}>
      <Text sx={textSX}><Initials /></Text>
    </Flex>
  )
}

export default Logo
/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import Goal from '../../images/goal-white.svg';

const PlayerGoal = (props) => {

  const styleSX = {
    display: 'inline-block',
    width: '15px',
    height: '15px',
    background: `url(${Goal}) no-repeat center`,
    backgroundSize: '15px 15px',
  }

  return (
    <Box sx={styleSX} {...props} />
  )
}

export default PlayerGoal